:root {
    --background: white;
    --link: #000;
    --link-shadow: rgba(0,0,0, 0.4);

    --border-top: #425EC4;
    --border-top-light: 1px solid #a6a6a6;
    --border-bottom: 7px solid #606060;
    --stripe: repeating-linear-gradient(#fff, #fff 1px, #f9f9f9 2px, #f9f9f9 3px, #fff 4px);
    // --gloss: linear-gradient(to top, #fff, #fefefe 5%, #c9c9c9 60%, #a0a0a0 84%,  #939393 85%, #dadada 85%, #ccc 95%, #bcbcbc 95%, #b8b8b8 100%);
    // --gloss: linear-gradient(to top, #fff, #fefefe 5%, #c9c9f9 60%, #a0a0d0 84%,  #9393c3 85%, #dadafa 85%, #ccf 95%, #bcbcfc 95%, #b8b8e8 100%);
    --gloss: linear-gradient(to top, #c9edfb, #89b5e7 60%, #A6C6EA 61%, #E4ECF7 99%, #AFBDE6 100%);
    // --light-gloss: linear-gradient(to top, #d3d3d3, #fefefe 5%, #bdbdbd 85%, #e6e6e6 85%, #dbdbdb 95%, #c3c3c3 100%);
    --light-gloss: linear-gradient(to top, #fff, #E3E3E3 60%, #EAEAEA 61%, #FCFCFC 99%, #e1e1e1 100%);
}

html {
    background: var(--stripe);
    display: flex;
    flex-direction: column;
    align-items: center;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", "Helvetica", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
}

footer {
    width: calc(100% - 2em);
    padding: 1em;
    text-align: center;
}

footer {
  font-size: smaller;

  .bio {
    text-align: left;
  }
}


.bio {
    margin: 2em;

    ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        justify-content: center;
        flex-wrap: wrap;

        li {
            margin-right: 1em;
        }
    }
}

nav {
    background: var(--stripe);
    background-size: cover;
    border-bottom: var(--border-bottom);
    border-image: var(--gloss) 0 0 100% 0;
    // box-shadow: inset 0 0 2px var(--link-shadow);
    max-width: 800px;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;

    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        padding: 0.4rem 0 0 0;
        flex-wrap: wrap;
        margin: 0;
        border-bottom: 1px solid var(--border-top);

        li {
            margin: 0;
            text-align: center;
            border-radius: 0.5rem 0.5rem 0 0;

            a {
                font-size: 1rem;
                font-weight: 200;
                color: var(--link);
                text-decoration: none;
                font-weight: normal;
                text-shadow: 0 2px 3px var(--link-shadow);
                background: var(--light-gloss);
                padding: 0.25rem 1rem;
                border-radius: 0.25rem 0.25rem 0 0;
                border-top: var(--border-top-light);
                border-left: var(--border-top-light);
                border-right: var(--border-top-light);
                display: block;
                margin-left: -1px;
                box-shadow: inset 0 1px 1px #e1e1e1;
            }
            a.current, a:hover {
                box-shadow: inset 0 1px 1px #AFBDE6;
                background: var(--gloss);
                border-color: var(--border-top)
            }
            
            a.current {
                position: relative;
                top: 1px;
            }
        }
    }


}

section {
    max-width: calc(800px - 2em);
    width: 100%;
    padding: 1em;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    background: rgba(255, 255, 255, 0.4);
}

a {
}

h1 {
    font-weight: 100;
    font-size: 3em;
    text-align: center;
    margin: 0;
}

h2, h3 {
    font-weight: normal;
    font-size: 1.5em;
    font-weight: 200;
}

subtitle {
    font-size: smaller;
    text-align: center;
    width: 100%;
    display: block;
}

.bio-photo {
    max-width: 100px;
    max-height: 100px;
    min-width: 50px;
    min-height: 50px;
    display: block;
    font-size: 0.8em;
    flex: 1;
    object-fit: contain;
    float: left;
}

hr {
    border: 0;
    border-top: var(--border-top);
}

table {
    overflow-x: scroll;
    max-width: 100%;
    display: block;
    border-collapse: collapse;
}

td {
    padding: 1em 0.5em;
}

th {
    border-bottom: var(--border-bottom);
}

img {
    max-width: 100%;
    max-height: 100vh;
    display: block;
    margin: auto;
}

form {
    display: flex;
    flex-direction: column;
    align-items: auto;
}

button {
    padding: 0.5rem;
    background: var(--highlight);
    color: var(--link);
    border: 0;
    box-shadow: 5px 10px 0 var(--highlight-trans);
    font-size: 1rem;
}

button:hover {
    box-shadow: 3px 8px 0 var(--highlight-trans);
}

button:active {
    box-shadow: 1px 6px 0 var(--highlight-trans);
}

label {
    flex: 1;
    display: flex;
    flex-direction: column;
}

@media screen and (min-height: 400px) {
  img {
        max-height: 400px;
    }
}
